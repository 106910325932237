<template>
	<div class="page_about">
		<top :bgWhite='true'></top>
		<div class="contentAll  padding-lr-30  top-80  padding-bottom-80 ">
			<div class=" bg-white padding content-box padding">
				<!-- <el-image src="/assets/image/center/left3.png" class="cover-img" fit="cover"></el-image> -->
				<p class="margin-tb-10 bold fs16 text-center">{{atype | titleTxt}}</p>
				<p class="inner-content" v-html="content">
					
				</p>
			</div>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		components: {
			bottom,
			top
		},
		data(){
			return{
				title:'',//标题
				content:'',//内容
				atype:'', //文章类型
			}
		},
		mounted(){
			this.atype = this.$route.query.type;
			this.getArticleDetail()
		},
		filters: {
			titleTxt(val) {
				switch (val){
					case '15':
						return '服务协议'
						break;
					case '23':
						return '隐私协议'
						break;
					case '25':
						return '关于我们'
						break;
					case '26':
						return '人才库手册'
						break;
					case '27':
						return '社区规范'
						break;
					default:
						break;
				}
			}
		},
		methods:{
			/**
			 * 获取文章
			 */
			getArticleDetail() {
				this.ajax('post', '/v1/5d63befcb25d9', {
					category_id: this.atype,
				}, res => {
					console.log(res.data, 'res');
					if (res.code == 1) {
						this.content = res.data.content
					} else {
						this.$message.error(res.msg);
					}
				}, err => {
					console.log(err);
				})
			},
		}
	}
</script>

<style  lang="scss">
html, body{
	background: #F6F7F9 !important;
	.content{
		width: 100%;
		background: #FFFFFF;
	}
}
.contentAll{
	width: 60%;
}
.content-box{
	width: 100%;
	.inner-content{
		font-size: 9px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 21px;
		color: #333333;
		opacity: 1;
	}
}
.cover-img{
	width: 100%;
	height: 280px;
	background: rgba(0, 0, 0, 0);
	opacity: 1;
	border-radius: 5px 5px 0px 0px;
}
</style>